/*
 * Bootstrap variable overrides:
 */

// Colors:
$primary: #0d6efd; // Suhrob's #0E6FFF would flip the text color to black due to too low contrast
$warning: #FFBA00;
$dark: #141414;

// Rounded buttons
$btn-border-radius: 10rem;
$btn-border-radius-sm: 10rem;
$btn-border-radius-lg: 10rem;
$btn-padding-x: 2rem;
$btn-padding-y: 0.875rem;

$font-family-sans-serif: Poppins, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-base: $font-family-sans-serif  !default;
$btn-font-weight: 600;

// Modals
$modal-dialog-margin: 4rem;
$modal-dialog-margin-y-sm-up: 6rem;
$modal-backdrop-opacity: 0.75;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

/*
 * Other global variables
 */
$lightblue: #E9F7FF;
$lightyellow: #FFF3CA;
